@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.universal-select-title {
	margin: $margin 0;
	font-size: 14px;
}

.universal-select-label {
	display: block;
	font-weight: normal;
	font-size: 12px;
	// line-height: 16px;
	margin-bottom: 4px;
}

li.MuiAutocomplete-option {
	position: relative;
	padding-left: 24px;
}

.checked-icon-position {
	position: absolute;
	top: 50%;
	left: 12px;
	transform: translateX(-50%);
}

.MuiOutlinedInput-notchedOutline {
	height: 32px !important;
	top: 0 !important;
}

.MuiInputBase-input {
	border-radius: 4px !important;
	padding: 9px !important;
	color: $black !important;
}

.top-inherit {
	input {
		text-align: left;
	}

	div {
		top: inherit;
	}
}

// fieldset {
// 	legend {
// 		display: none !important;
// 	}
// }

.select-filter__multiple {
	margin: 0 $margin;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-right: 65px;
	position: absolute;
	left: 0;
	width: 100%;
	color: $black;
	pointer-events: none;
}

.transparent-text {
	color: transparent;
	pointer-events: none;
}
