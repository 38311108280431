@import '@root-gipro/styles/config/variables.scss';

body {
	font-size: 16px;
	line-height: 16px;
	font-family: 'Roboto';
	color: $black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include adapt(375px) {
		min-width: 375px;
		overflow-y: auto;
	}
}
.MuiListItemText-root {
	margin-left: 8px !important;
}
.container {
	margin: 0 auto;
	max-width: 1280px;
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
	@include adapt(1280px) {
		width: 100%;
	}
}
.personal-area__container {
	margin: 0 auto;
	max-width: 1280px;
	position: relative;
	@include adapt(768px) {
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
	}
}

.user-projects__container {
	display: flex;
	position: relative;
	margin: 0 auto;
	max-width: 1280px;
	padding: 0;
	@include adapt(375px) {
		min-width: 375px;
		overflow: hidden;
	}
}
.container-full {
	margin: 0 auto;
	max-width: 1280px;
	padding-left: 16px;
	padding-right: 16px;
	@include adapt(1280px) {
		width: 100%;
	}
	@include adapt(768px) {
		padding: 0;
	}
}
.controller {
	user-select: none;
}
.currency {
	font-size: 12px;
	line-height: 16px;
	color: $light-grey;
}

[class*='-icon'] {
	transition: $transition;
}

.hidden {
	display: none;
}

.link {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&-blue {
		color: $main-blue;
	}

	&-white {
		color: #fff;
	}

	&-grey {
		color: $grey;
	}

	&-disabled {
		color: $grey;

		&:hover {
			text-decoration: none;
		}
	}
}

.btn {
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	text-align: center;
	color: #fff;
	cursor: pointer;

	&-blue {
		background-color: $main-blue;

		&:hover {
			opacity: 0.9;
		}
	}

	&-link {
		border-radius: 0;
		color: $main-blue;

		&:hover {
			text-decoration: underline;
		}
	}

	&-outline {
		border: $main-blue;
		color: $main-blue;
	}
}

.title {
	font-size: 20px;
	font-weight: 500;

	&-modal {
		font-size: 20px;
		line-height: 24px;
	}
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.text-align-left {
	text-align: left;
}

// Grid
.flex-basis-auto {
	flex-basis: auto !important;
}

.flex-basis-400 {
	flex-basis: 400px;
}

.width-100 {
	width: 100%;
}

.width-100-important {
	width: 100% !important;
}
.min-w-32 {
	min-width: 32px !important;
}
.row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.flex {
	display: flex;
	align-items: center;
}

.flex-between {
	justify-content: space-between;
}
.justify-content-center {
	justify-content: center;
}
.pointer {
	cursor: pointer;
}
.flex-wrap-wrap {
	flex-wrap: wrap;
}
.ml-8 {
	margin-left: 8px;
}
.ml-4 {
	margin-left: 4px;
}
.mr-8 {
	margin-right: 8px;
}
.mr-4 {
	margin-right: 4px;
}
.mb-16 {
	margin-bottom: 16px;
}
.mb-8 {
	margin-bottom: 8px;
}
.mb-24 {
	margin-bottom: 24px;
}
.mt-8 {
	margin-top: 8px;
}
.mt-16 {
	margin-top: 16px;
}
.pl-24 {
	padding-left: 24px;
}

.w-50 {
	width: 50px;
}
.col-1 {
	width: 1 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-2 {
	width: 2 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-3 {
	width: 3 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-4 {
	width: 4 / 12 * 100%;
}

.col-5 {
	width: 5 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-6 {
	width: 6 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-7 {
	width: 7 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-8 {
	width: 8 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-9 {
	width: 9 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-10 {
	width: 10 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-11 {
	width: 11 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-12 {
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.flex-wrap-none {
	flex-wrap: inherit !important;
}

.grid-container {
	display: flex;
}
