@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.navLink,
.buttonLink {
	display: inline;
	// margin-right: 16px;
	padding: 8px;
	font-size: 15px;
	line-height: 16px;
	color: #fff;
	transition: $transition;
	cursor: pointer;
	position: relative;

	&:hover {
		color: $main-blue;
	}
	@include adapt(425px) {
		// margin-right: 8px;
		padding: 4px;
	}
}

.buttonLink {
	background-color: transparent;
	outline: none;
	border: none;
}

.active {
	color: $main-blue;
}

.menuItems {
	padding-left: 177px;
	@include adapt(992px) {
		padding-left: 0;
	}
}
