@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.card-container {
	transition: $transition;
	display: flex;
	flex-wrap: wrap;
	border: $border;
	border-radius: 16px;
	margin-bottom: 16px;
	height: 100%;
	.flex {
		display: flex;
		align-items: center;
	}
	.icon-wrapper {
		margin-left: 12px;
		cursor: pointer;
		opacity: 0;
		svg {
			color: #3f4f5e;
			&:hover {
				color: #1baaf0;
			}
		}
	}
	.icon-wrapper-favorite {
		cursor: pointer;
		margin-left: 12px;
		opacity: 1;
	}
	.icon-wrapper_active {
		margin-left: 12px;
		svg {
			color: #3f4f5e;
			&:hover {
				color: #1baaf0;
			}
		}
	}
	.link-icon {
		width: 16px;
		height: 16px;
	}
	&:hover {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

		.card-container__actions {
			color: #1baaf0;
			opacity: 1;
		}
		.icon-wrapper {
			opacity: 1;
		}
	}
	@include adapt(480px) {
		border: none;
		border-top: $border;
		border-radius: 0;
	}
	.card-container__description {
		-webkit-line-clamp: 3;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
.card-container__actions {
	opacity: 0;
}
.card-container__left {
	position: relative;
	width: calc(100% - 320px);
	padding: 16px;
	@include adapt(768px) {
		width: 100%;
	}
}
.card-container__right {
	width: 320px;
	padding: 16px;
	@include adapt(768px) {
		width: 100%;
	}
}
.card-container__code {
	font-size: 18px;
	line-height: 24px;
	display: flex;
	align-items: center;
	position: relative;
	white-space: nowrap;
	@include adapt(480px) {
		font-size: 16px;
		padding-left: 22apx;
	}
	.code {
		margin-left: 4px;
		cursor: pointer;
	}
}
.card-container__title {
	line-height: 24px;
	margin-top: $margin;
}
.card-container__detailed-btn {
	font-size: 13px;
	padding: 0;
	color: $blue-dark;
	@include adapt(768px) {
		display: none;
	}
	&:hover {
		color: #1baaf0;
	}
}
.card-container__wrapper-actions {
	display: flex;
	align-items: center;
	width: 100%;
	margin-left: 5px;
}
.card-container__detailed-btn {
	cursor: pointer;
}
.card-container__head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 27px;
	overflow: hidden;
}
.card-container__date {
	font-size: 14px;
	margin-top: $margin;
	color: $grey;
}
.card-container__description {
	font-size: 14px;
	line-height: 16px;
	margin-top: $margin;
}
.card-container__right__item {
	margin-bottom: $margin * 2;
	&:last-child {
		margin-bottom: 0;
	}
}
.card-container__right__item-value,
.card-container__detailed-info__item-value {
	font-size: 18px;
	font-weight: 400;
	line-height: 16px;
	display: flex;
	align-items: end;
	gap: 5px;
	// justify-content: space-between;
	// border: 1px solid #e0e0e0;
	border-radius: 4px;
	min-height: 24px;
	// padding: 8px;
	// margin-top: 4px;
	cursor: default;
	// background-color: white;
	span {
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.34);
	}
}

.card-container__detailed {
	display: flex;
	// background-color: rgba(0, 0, 0, 0.03);
	@include adapt(768px) {
		display: none;
	}
}
.MuiCollapse-container {
	width: 100%;
}
.card-container__detailed--column {
	flex-direction: column;
}
.card-container__right__item-title,
.card-container__detailed-info__item-title {
	font-size: 12px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.87);
}

.space-between {
	justify-content: space-between;
}
.flex-end {
	justify-content: flex-end;
}

.card-container__detailed-info {
	padding: 16px;
	display: flex;
	// flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	// background-color: rgba(0, 0, 0, 0.03);
}
.common-projects-detailed-width {
	width: calc(100% - 320px);
	@include adapt(768px) {
		width: 100%;
		order: 2;
	}
	.card-container__detailed-info__item {
		width: 48%;
	}
}
.purchases-detailed-width {
	width: 100%;
	.card-container__detailed-info__item {
		width: 33%;
		@include adapt(1280px) {
			width: 48%;
		}
	}
}
.card-container__detailed-price {
	width: 320px;
	padding: 16px;
	@include adapt(768px) {
		width: 100%;
		order: 1;
	}
}

.card-container__detailed-info__item-title {
	height: 32px;
	display: flex;
	align-items: flex-end;
}
.card-container__comment {
	margin-top: $margin;
}
.card-container__comment-value {
	position: relative;
	font-size: 14px;
	line-height: 16px;
	padding: 8px;
	border-radius: 4px;
	margin-top: 4px;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;

	&.show-border {
		border: $border;
	}
	&:after {
		content: '';
		display: block;
		height: 8px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
	}
}
.card-container__comment-shrt-txt {
	font-size: 12px;
	line-height: 16px;
}

.cost-works-icon {
	width: 16px;
	height: 24px;
}

.linkIcon {
	display: block;
	color: #3f4f5e;
	width: 16px;
	margin-left: 8px;
	cursor: pointer;
}
.linkIcon:hover {
	color: #1baaf0;
}
