.container {
    padding: 20px;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  }
  
  .header {
    margin-bottom: 20px;
    color: #333;
    font-size: 18px;
  }
  
  .statisticBlock {
    margin-bottom: 20px;
    background: none;
    padding: 15px;
    border-radius: 4px;
  }
  .statisticBlock:first-of-type {
    margin-bottom: 20px;
    background: none;
    padding: 15px;
    border-radius: 4px;
  }
  
  .statisticItem, .historyItem {
    /* width: 600px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(203, 203, 203);
  }
  
  
  
  .statisticValue, .historyValue {
    color: #666;
  }
  
  .historyHeader {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;

  }
  
  .historyDate {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .statisticLabel,
    .statisticValue,
    .historyLabel,
    .historyValue {
      font-size: 12px;
    }
  
    .header {
      font-size: 16px;
    }
  
    .historyHeader {
      font-size: 14px;
    }
  }