@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.wrapper {
	max-height: auto;
	overflow-y: auto;
	border-radius: 12px;
}

.shrtTxt {
	font-size: 12px;
	line-height: 16px;
	font-weight: 300;
}
.item {
	margin-top: 16px;
}
.blockTitle {
	display: flex;
	align-items: center;
}
.title,
.titleMore {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
}
.icon {
	margin-right: 12px;
}
.svg {
	width: 8px;
	height: 8px;
	fill: transparent;
	cursor: pointer;
	path {
		fill: $light-grey;
		transform: all 0.3s easy-in-out;
	}
	&:hover {
		path {
			fill: $error;
		}
	}
}
.descriptions {
	border-left: 1px solid $border-color;
	padding-left: 16px;
	margin-left: 4px;
}
.price {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	padding-top: 8px;
}
.includes {
	margin-top: 8px;
}
.txt {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
}
.arrowSVG {
	fill: transparent;
	width: 10px;
	height: 6px;
	margin-left: 4px;
	transition: all ease-in-out 0.4s;
	path {
		stroke: #3f4f5e;
	}
}
.rotateSVG {
	transform: rotate(180deg);
}
.includesItem {
	display: flex;
	margin-top: 8px;
}
.text {
	margin-left: 4px;
}
.subscription {
	margin-top: 16px;
}
.wrapperTiming {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.timing {
	display: inline-flex;
	align-items: center;
	// background-color: #e0e0e0;
	width: auto;
	height: 35px;
	border-radius: 6px;
	margin-top: 4px;
}
.time {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 32px;
	border-radius: 6px;
	color: $placeholder-color;
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;
	cursor: pointer;
}
.timeActive {
	background-color: #01b0e9;
	color: white;
}
.discount {
	height: 32px;
	border: 1px solid $blue;
	border-radius: 20px;
	color: $blue;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
	padding: 0 8px;
}
.addMore {
	margin-top: 16px;
}
.addMoreBtn {
	display: block;
	cursor: pointer;
	width: 100%;
	height: 32px;
	border: 1px solid $blue-dark;
	border-radius: 4px;
	background-color: $table-bg;
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;
}
.titleMore {
	display: inline-block;
	cursor: pointer;
	margin-top: 8px;
}
.order {
	border-radius: 8px;
	margin-top: 16px;
	margin-bottom: 24px;
}
.heading {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.orderItem,
.totalItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.orderItem {
	margin-top: 8px;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}
.total {
	border-top: 1px solid $light-grey;
	margin-top: 8px;
}
.totalItem {
	margin-top: 8px;
	font-size: 24px;
	line-height: 25px;
	font-weight: 400;
	text-transform: uppercase;
}

.descriptionsInfo {
	margin-top: 15px;
}
.descriptionsInfoItemTitle {
	display: block;
	margin-top: 10px;
	margin-bottom: 4px;
	font-weight: 500;
}

.select {
	display: block;
	border: 1px solid $light-grey;
	margin: 10px 0;
	border-radius: 3px;
}
