@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.wrapper {
	width: 1000%;
	min-height: 100vh;
	height: auto;
	background-color: $bg-personal-aria;
}
.tariffs {
	padding: 16px;
	margin-top: 30px;
}

.title {
	font-size: 18px;
	line-height: 24px;
}
.list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 8px;
	padding-top: 16px;

	@include adapt(768px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include adapt(480px) {
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: normal;
	}
}
