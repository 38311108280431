.close-icon {
	width: 10px;
	height: 10px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/close.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.search-icon {
	width: 22px;
	height: 22px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/search.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.star-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/star.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.tag-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/add-tag.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.common-project-icon {
	width: 24px;
	height: 21px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/common-project.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.purchase-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/purchase.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.clock-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/clock.svg);
	background-repeat: no-repeat;
	background-position: center;
	padding: 10px;
}

.dropdown-icon {
	width: 12px;
	height: 6px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/dropdown.svg);
	background-repeat: no-repeat;
	background-position: center;
	padding: 6px;
	transition: $transition;
}
.dropdown-icon-bold {
	width: 12px;
	height: 6px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/dropdown-bold.svg);
	background-repeat: no-repeat;
	background-position: center;
	padding: 6px;
	transition: $transition;
	transform: rotate(-90deg);
}
.sort-icon {
	width: 16px;
	height: 12px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/sort.svg);
	background-repeat: no-repeat;
	background-position: center;
	padding: 10px;
	transition: $transition;
}

.dropdown-fat-icon {
	width: 12px;
	height: 6px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/dropdown-fat.svg);
	background-repeat: no-repeat;
	background-position: center;
	padding: 6px;
	transition: $transition;
}

.arrow-left-icon {
	width: 6px;
	height: 10px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/arrow-left.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.popup-icon {
	width: 24px;
	height: 24px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/popup.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.filter-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/filter.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.dots-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/dots.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.comment-icon {
	width: 20px;
	height: 18px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/comment.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.graphics-icon {
	width: 16px;
	height: 18px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/graphics.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.document-active-icon {
	width: 16px;
	height: 18px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/document-active.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.three-line-icon {
	width: 16px;
	height: 18px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/three-line.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.query-icon {
	width: 15px;
	height: 15px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/query.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.repost-icon {
	width: 14px;
	height: 15px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/repost.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.copy-icon {
	width: 15px;
	height: 18px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/copy.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.info-icon {
	width: 16px;
	height: 16px;
	display: block;
	cursor: pointer;
	background-image: url(/assets/images/svg/info.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.success-icon {
	width: 16px;
	height: 16px;
	display: block;
	background-image: url(/assets/images/svg/succcess.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.error-icon {
	width: 16px;
	height: 16px;
	display: block;
	background-image: url(/assets/images/svg/error.svg);
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.user-projects-icon {
	width: 13px;
	height: 16px;
	display: block;
	background-image: url(/assets/images/svg/user-projects.svg);
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.arrow-right-icon {
	width: 6px;
	height: 10px;
	display: block;
	background-image: url(/assets/images/svg/arrow-right.svg);
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.download-icon {
	width: 24px;
	height: 24px;
	display: block;
	background-image: url(/assets/images/svg/download.svg);
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.delete-icon {
	width: 18px;
	height: 18px;
	display: block;
	background-image: url(/assets/images/svg/delete.svg);
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.file_icon {
	width: 12px;
	height: 12px;
	background-image: url('/assets/images/svg/file.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.add-unc-icon {
	width: 18px;
	height: 18px;
	background-image: url('/assets/images/svg/add-unc-icon.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.download-template-icon {
	display: block;
	width: 18px;
	height: 18px;
	background-image: url('/assets/images/svg/download-template.svg');
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
}
.btn-uploader-icon {
	display: block;
	width: 18px;
	height: 18px;
	background-image: url('/assets/images/svg/uploader.svg');
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
}
.download-file-icon {
	display: block;
	width: 18px;
	height: 18px;
	background-image: url('/assets/images/svg/download-file.svg');
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
}
.remove-icon {
	display: block;
	width: 18px;
	height: 18px;
	background-image: url('/assets/images/svg/basket.svg');
	background-repeat: no-repeat;
	background-position: left center;
	background-color: transparent;
	border: none;
	align-self: start;
}
.checked-icon {
	display: block;
	width: 10px;
	height: 6px;
	background-image: url('/assets/images/svg/checked-icon.svg');
	background-repeat: no-repeat;
	background-position: left center;
	background-color: transparent;
}
