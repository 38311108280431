@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $white;
	padding: 16px;
	max-width: 300px;
	justify-self: center;
	@include adapt(768px) {
		margin-bottom: 16px;
	}
}
.imageWrapper {
	max-width: 100%;
	img {
		max-width: 221px;
		object-fit: contain;
		margin: auto;
	}
}

.title {
	margin-top: 21px;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 1px;
	font-weight: 700;
	text-transform: uppercase;
}
.subTitle {
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	margin-top: 4px;
}
.description {
	margin: 12px 0 0;
	font-size: 14px;
	line-height: 120%;
	color: #292a34;
}
.price {
	margin-top: 12px;
	font-size: 18px;
	line-height: 14px;
}
.shrtText {
	display: inline;
	font-size: 12px;
	margin-bottom: 8px;
	line-height: 14px;
	color: $placeholder-color;
}
.switch {
	height: 28px;
	margin-top: 6px;
	display: flex;
	align-items: center;
	font-size: 13px;
	line-height: 16px;
}

.txt {
	font-size: 13px;
	line-height: 16px;
}
.list {
	margin-top: 16px;
}
.item {
	display: flex;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 8px;
}
.text {
	margin-left: 4px;
}
.wrapButton {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 15px;
	margin-top: 16px;
}
.cardLink {
	color: inherit;
}
