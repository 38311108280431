@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.aside-filter-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: $transition;
	height: 100%;
	flex-basis: 320px;

	@include adapt(992px) {
		transform: translateX(-356px);
		width: 0;
		flex-basis: 0;
		padding: 0;
		transition: $transition;
	}
}

.aside-filter-copyright {
	padding: 0 16px;
	font-size: 12px;
	color: #c3c3c3;
	text-align: center;
	@include adapt(992px) {
		transform: translateX(-356px);
	}
	@include adapt(480px) {
		display: none;
	}
}
.aside-filter__item-select {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.aside-filter__item-name {
	padding-right: 40px;
	&__filter {
		padding: 0;
		padding-right: 8px;
	}
}

.aside-filter__expandIcon {
	border-radius: 6px;
	overflow: hidden;
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	background-color: transparent;
}

.aside-filter__divider {
	position: absolute;
	bottom: 0;
	left: -16px;
	right: 0;
	width: 288px;
}

.is-filter-open {
	.aside-filter-container {
		transition: $transition;
		@include adapt(992px) {
			transform: translateX(0);
			width: 320px;
			flex-basis: 320px;
		}
		@include adapt(480px) {
			width: 0 !important;
		}
	}
	.aside-filter,
	.aside-filter-copyright {
		transform: translateX(0);
		transition: $transition;
		@include adapt(480px) {
			position: absolute;
			width: 288px !important;
			z-index: 10;
		}
	}
	.header-substrate {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -10;
		background-color: $blue-dark;
		@include adapt(480px) {
			display: none;
		}
	}
}

.aside-filter {
	position: relative;
	padding: 16px;
	margin: 16px 0 16px 16px;
	border-radius: 16px;
	background-color: $filter-blue;
	width: 288px;
	transition: $transition;

	@include adapt(992px) {
		// transform: translateX(-356px);
	}
	@include adapt(480px) {
		margin-top: 72px;
		z-index: -10;
		border: $border;
		padding: 16px;
	}

	&__form {
		&-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
		}
	}

	&__reset {
		font-size: 13px;
		line-height: 16px;
	}

	&__item {
		margin-bottom: calc(16px);

		&-head {
			@include flex-between-center;
		}

		&-select {
			display: flex;
			align-items: center;
			font-size: 15px;
			font-weight: 500;

			cursor: pointer;
			user-select: none;

			& [class*='-icon'] {
				margin-right: $margin;
			}

			&.open {
				[class*='-icon'] {
					transform: rotate(0deg);
					transition: $transition;
				}
			}
		}
	}

	&__select-filter {
		// margin-top: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__checkbox {
		margin-top: $margin;
		display: flex;
		align-items: center;
	}
	&__checkbox--badge {
		position: absolute;
		margin-left: 3px;
		font-size: 11px;
	}
	&__result {
		position: sticky !important;
		padding: 20px 16px 10px;
		width: 288px;
		margin-left: -16px;
		bottom: 0;
		background-color: #f5f5f5;
		margin-top: calc(32px);
		z-index: 999;

		// &::before {
		// 	content: '';
		// 	display: block;
		// 	width: 100%;
		// 	height: 1px;
		// 	background-color: rgba(0, 0, 0, 0.12);
		// 	margin: 16px 0;
		// }

		&-btn {
			width: 100%;
		}
	}
	&__radioPrice {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: 15px;
		margin-bottom: 19px;
	}
	&__radioPrice-item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 10px;
	}
}
.aside-filter__input {
	position: relative;
	// margin-top: 8px;
}
.aside-filter__title {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
}

.shrt-text-input {
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.54);
}

.tooltip {
	padding: 2px 4px;
	line-height: 1.2;
	font-size: 14px;
	font-weight: 300;
}

.chip_tech_filter {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	&__uncs {
		min-width: 60px;
		background-color: #e0e0e0;
		border-radius: 10px;
		padding: 7px;
		margin: 5px 5px 0 0;
		text-align: center;
		&:hover {
			cursor: pointer;
			background-color: #cecece;
		}
	}
}
