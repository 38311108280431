*,
*::before,
*::after {
	box-sizing: border-box;
}

ul[class],
ol[class] {
	list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd,
button,
form,
input {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	margin: 0;
	padding: 0;
}

body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

a {
	&:not([class]) {
		text-decoration-skip-ink: auto;
	}
	&[class] {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
	display: block;
	object-fit: cover;
}

article > * + * {
	margin-top: 1em;
}

// input,
// button,
// textarea,
// select {
// 	font: inherit;
// 	outline: none;
// 	border: none;
// 	background-color: transparent;
// }

table {
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}
