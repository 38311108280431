@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.projects-container {
	display: flex;
	position: relative;
	margin: 0 auto;
	max-width: 1280px;
	padding: 0;
	@include adapt(375px) {
		min-width: 375px;
		//overflow: hidden;
	}
}
.projects-wrapper {
	position: relative;
	width: calc(100% - 288px); // 256px ширина фильтра - 16px  отступ
	padding: 0 16px;
	@include adapt(992px) {
		width: 100%;
	}
	@include adapt(480px) {
		padding: 0;
	}
	.project__list {
		//max-height: calc(100vh - 115px);
		//overflow-y: scroll;

		// .project__list_loader {
		// 	position: absolute;
		// }
	}
}
