.table_rows{
    display: contents !important;
}
.high_row{
    border-bottom: 0px !important;
}


.multi_cell{
    position: relative  !important;
}
.multi_cell:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 2px; 
    width: calc(100% - 16px); 
    border-bottom: 1.5px solid white;

}