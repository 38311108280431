.configure {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 400px;
	min-height: 350px;
	z-index: 1302;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	/* тень */
}

.configure_zone {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1302;
	width: 100vw;
	max-height: 100vh;
	height: 100%;
}

.content {
	padding: 15px;
}

.content_header {
	font-size: 18px;
}

.configure_block {
	margin: 20px 0;
	font-size: 14px;
	color: gray;
}

.configure_footer_buttons {
	display: flex;
	justify-content: space-between;
}

.configure_block_item {
	margin-top: 15px;
}

.select-input {
	width: 100%;
	margin-top: 10px;
	border: 0.5px solid gray;
}

.MuiSelect-select:focus {
	border-radius: 0;
	background: none !important;
}

.select_arrow_icon {
	transition: all 1.3s ease 3s;
}

.active {
	//   transform: rotate(180deg);
}
.MuiSelect-icon {
	display: none !important;
}
