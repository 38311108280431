@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.unc_add_wrapper {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
}
.scroll-wrapper {
	padding: 0 16px;

	@include adapt(991px) {
		overflow: auto;
	}
	&::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: $border-color;
		-webkit-border-radius: 100px;
	}
	&::-webkit-scrollbar:hover {
		width: 12px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(108, 108, 108, 0.455);
		-webkit-border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb:hover,
	&::-webkit-scrollbar-thumb:active {
		background-color: rgba(123, 123, 123, 0.455);
	}
}

.scroll-wrapper:focus {
	outline: none;
}

.unc_add_container {
	width: 1248px;

	@include adapt(1200px) {
		width: calc(1200px - 32px);
	}
	@include adapt(1100px) {
		width: calc(1100px - 32px);
	}
	@include adapt(1024px) {
		width: calc(1024px - 32px);
	}
	@include adapt(991px) {
		width: 991px;
	}
}

.unc_add_filter {
	width: 1000px;

	@include adapt(1200px) {
		width: calc(1200px - 32px);
	}
	@include adapt(1100px) {
		width: calc(1100px - 32px);
	}
	@include adapt(1024px) {
		width: calc(1024px - 32px);
	}
	@include adapt(991px) {
		width: 991px;
	}
}

.unc_add_filter_container {
	width: 1000px;

	@include adapt(1200px) {
		width: calc(1200px - 32px);
	}
	@include adapt(1100px) {
		width: calc(1100px - 32px);
	}
	@include adapt(1024px) {
		width: calc(1024px - 32px);
	}
	@include adapt(991px) {
		width: 991px;
	}
}

.unc_add {
	display: flex;
	justify-content: space-between;
	position: relative;
	table {
		width: 100%;
	}
	th {
		color: rgba(0, 0, 0, 0.5);
		background: $table-bg;
		border-right: 1px solid rgba(56, 130, 136, 0.1);
		font-size: 10px;
		&:last-of-type {
			border-right: none;
		}
	}
	.unc_counter::placeholder {
		color: inherit;
	}
	.table_wrapper {
		height: 550px;
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 16px;
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
			background-color: $border-color;
			-webkit-border-radius: 100px;
		}
		&::-webkit-scrollbar:hover {
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(108, 108, 108, 0.455);
			-webkit-border-radius: 100px;
		}

		&::-webkit-scrollbar-thumb:hover,
		&::-webkit-scrollbar-thumb:active {
			background-color: rgba(123, 123, 123, 0.455);
		}
		&__filter_sidebar{
			height: 520px;
		}
	}
	.action_btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
	}
	.filter_side_btns:first-of-type {
		justify-content: end;
		margin-left: 20px;
	}
	.filter_side_btns {
		justify-content: end;
		margin-right: 20px;
	}
	.filter_side_btns button {
		width: 200px;
	}
	.fullWidth {
		width: 100%;
	}
	.rej_btn {
		// margin-left: 16px;
	}
}

.modal_filter_uncs {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;

	.action_btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
	}
	.filter_side_btns {
		justify-content: end;
		margin-right: 20px;
	}
	.filter_side_btns button {
		width: 200px;
	}
	.select_filter{
		position: absolute;
		bottom: 16px;
		right: 0;
	}
	.select_configure{
		position: absolute;
		bottom: 16px;
		left: 0;
	}
}

.uncs_list {
	width: calc(100% - 376px); // 360px ширина правого блока + 16px отступ
	min-width: 615px;
	padding: 16px 0 16px 16px;
	background-color: #fff;
	border-radius: 6px;
	@include adapt(991px) {
		width: 615px;
	}
	h1 {
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
	}
	.options {
		display: flex;
		justify-content: space-between;
		margin: 16px 0;
	}
	.toggler {
		// width: 157px;
	}

	&__item-table {
		padding: 6px 0;
		cursor: pointer;
	}

	&__item-table:hover {
		text-decoration: underline;
	}

	.MuiTab-root {
		min-height: 32px !important;
		.MuiTab-wrapper {
			font-weight: normal;
			font-size: 13px;
			line-height: 16px;
		}
		.MuiTabs-indicator {
			display: none !important;
		}
		.Mui-selected {
			border-radius: 4px;
			background: rgba(27, 170, 240, 0.1);
		}
	}
}

.filter_side_open {
	width: 1000px;
}

.unc_search {
	width: 200px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	padding-right: 16px;
	.MuiIconButton-root {
		padding: 6px;
	}
}
.search {
	position: absolute;
	right: 24px;
	border-radius: 24px;
	border: $border;
	// border-right: none;
	transition: all 0.4s ease-out;
}
.search_hide {
	width: 0;
	height: 32px;
	opacity: 0;
}
.search_show {
	width: 100%;
	height: 32px;
	opacity: 1;
	margin-right: 40px;
}
.search-input {
	width: 100%;
	height: 100%;
	padding: 0 16px;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
}
.scrollarea-content {
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	padding-right: 16px;
}
.add_table {
	border-collapse: collapse;
	th {
		height: 17px;
		border: 1px solid rgba(56, 130, 136, 0.1);
	}
	td {
		font-size: 10px;
		height: 20px;
		border: 1px solid rgba(56, 130, 136, 0.1);
		min-width: 30px;
		padding: 0 5px;
	}
}
.wrapper-select-table {
	width: 100%;
	position: absolute;
}
.main_table {
	position: absolute;
	width: calc(100% - 376px);
	height: 683px;
	z-index: -10;
	opacity: 0;
	overflow: hidden;
	background-color: #fff;
	border-radius: 6px;

	@include adapt(992px) {
		width: 100%;
	}
	.active_unc_td {
		transition: background 0.2s ease;
		cursor: pointer;
		&:hover {
			background: #f5f8f9;
		}
	}
	.selected-cell {
		background-color: #f5f9da; /* Любой цвет, который вы хотите использовать */
	}
	&__show {
		z-index: 100;
		opacity: 1;
	}
	.pannel_header {
		padding: 24px 20px 14px;
		width: 100%;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		.pannel_header-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;
		}
		.title {
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
		}
		.note_btn,
		.subtitle {
			text-align: right;
		}
		.note_btn {
			cursor: pointer;
			color: $main-blue;
		}
		.subtitle {
			color: $light-grey;
			width: 185px;
		}
		.table_num {
			color: $main-blue;
		}
		.pannel__nav-btn {
			color: rgba(0, 0, 0, 0.56);
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			text-transform: capitalize;
			height: 32px;
		}
	}
	table {
		tr {
			height: 15px;
			th,
			td {
				text-align: center;
				padding: 2px;
			}
			td {
				&.price {
					background: #f5f6fa;
				}
			}
		}
	}
	.select-table__header {
		display: flex;
		align-items: center;
		.btn {
			width: 85px;
			padding: 0;
			text-align: center;
			margin-right: 20px;
		}
	}

	&__filter_side {
		width: 1000px;
		height: 727px;
	}
}

.point_name {
	margin-top: 12px;
	margin-bottom: 8px;
	font-weight: 500;
}
.point_elem {
	border-bottom: $border;
	// padding-bottom: 8px;
	cursor: pointer;
	.point {
		// margin: 12px 0 12px 10px;
		padding: 8px 12px;
	}
	.point:hover {
		text-decoration: underline;
	}
}

.aside {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 360px;
	position: relative;
	margin-left: 16px;
	padding: 16px;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	background-color: #fff;
	border-radius: 6px;
}
.table-right__head {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
	padding-bottom: 8px;
	border-bottom: $border;
	.head__item {
		width: calc(100% / 3 - 24px);
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: $grey;
		&:last-child {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
		}
	}
}
.del-icon {
	width: 8px;
	height: 8px;
	background-image: url(/assets/images/svg/close-icon.svg);
}
.body__row-wrapper {
	margin-top: 8px;
}
.body__row-cell {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
	padding-bottom: 8px;
	border-bottom: $border;
	.del-icon {
		cursor: pointer;
	}

	.row-cell {
		display: flex;
		align-items: center;
		width: 100%;
		width: calc(100% / 3 - 16px);
		height: 32px;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		&:last-child {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
		}

		.unc_counter {
			display: block;
			width: 100%;
			height: 100%;
			border: 1px solid $border-color;
			border-radius: 4px;
			text-align: left;
			padding: 4px 8px;
			outline: none;
			&::placeholder {
				color: $light-grey !important;
			}
			&:focus {
				border: 1px solid $main-blue;
			}
		}
		.MuiButtonBase-root {
			padding: 2px;
		}
	}
}
.add-unc__voltage-wrapper {
	display: flex;
	align-items: center;
	padding: 4px 8px;
	width: 100%;
	height: 100%;
	border: 1px solid $border-color;
	border-radius: 4px;
	.voltage-select {
		color: $light-grey;
		cursor: pointer;
	}
}
.directions {
	width: 100%;
	min-height: 550px;
	max-height: 736px;
	background: #ffffff;
	border-radius: 4px;
	padding: 16px;
	&_wrapper {
		min-width: 700px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
	}
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 24px;
		margin-bottom: 16px;
	}
	.title {
		color: $black;
	}
	.close_btn {
		font-size: 14px;
		color: $main-blue;
		cursor: pointer;
	}
	.text {
		font-size: 14px;
		padding-right: 12px;
		line-height: 20px;
	}
}
.select-table__header {
	.MuiTab-root {
		min-height: 32px !important;
		.MuiTab-wrapper {
			font-weight: normal;
			font-size: 13px;
			line-height: 16px;
		}
		.MuiTabs-indicator {
			display: none !important;
		}
		.Mui-selected {
			border-radius: 4px;
			background: rgba(27, 170, 240, 0.1);
		}
	}
}

.toggler {
	min-height: 32px !important;
	.MuiTab-wrapper {
		font-weight: normal;
		font-size: 13px;
		line-height: 16px;
	}
	.MuiTabs-indicator {
		display: none !important;
	}
	.Mui-selected {
		border-radius: 4px;
		background: rgba(27, 170, 240, 0.1);
	}
}
.interpol_wrapper {
	position: relative;
}
.interpol {
	.interpol_value {
		width: 140px;
		display: flex;
		align-items: center;
		display: inline-block;
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.interpol_elem {
		margin-top: 8px;
		position: relative;
		&:first-child {
			margin-top: 0;
		}
		.interpol_title {
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			display: flex;
			align-items: flex-end;
			margin-bottom: 4px;
		}
		.current_value {
			border: $border;
			border-radius: 4px;
			width: 100%;
			height: 32px;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			padding: 16px;
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;

			&::before {
				content: '';
				position: absolute;
				width: 14px;
				height: 8px;
				background-image: url('/assets/images/Vector47.png');
				right: 12px;
				top: calc(50% - 4px);
			}
		}
	}
	.interpol_list {
		position: absolute;
		width: 100%;
		max-height: 120px;
		left: 0;
		top: 52px;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		z-index: 2;
		overflow-y: auto;
		& div {
			padding: 4px 16px;
			// min-height: 32px;
			display: flex;
			// border-bottom: 1px solid #e0e0e0;
			align-items: center;
			cursor: pointer;
			transition: color 0.2s ease, color 0.2s ease;
			&:hover {
				color: $main-blue;
			}
		}
	}
	input {
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		width: 100%;
		height: 32px;
		line-height: 40px;
		padding: 0 16px;
		outline: none;
		.hands_input {
			border: none;
			outline: none;
			height: 100%;
			width: 100px;
			padding: 0;
		}
	}
}

.overflow-visible {
	.MuiPopover-paper {
		overflow-x: visible !important;
		overflow-y: visible !important;
	}
}

.clear_purchases_button{
	border: none;
	margin: 5px;
	color: #1BAAF0;
	background-color: transparent;
	&:hover{
		cursor: pointer;
		font-weight: 600;
	}
}