.switcher {
	margin-top: 15px;
}

.choose_button {
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 100%;
	height: 36px;
	margin-top: 30px;
	cursor: pointer;
	font-size: 1rem;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    outline: none;
}

.choose_button:hover {
	background-color: #cecdcd;
	transition: all 0.3s ease ;
}

.search_input .MuiOutlinedInput-adornedEnd {
	border-radius: 2px;
	height: 40px;
	padding: 0;
}

.selected_items_title {
	font-size: 15px;
	font-weight: 500;
	margin: 20px 0 10px 0;
}

.selected_items_block {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.selected_item {
	min-width: 100px;
	min-height: 30px;
	border: 1.4px solid rgb(62, 62, 62);
	color: gray;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	position: relative;
	cursor: pointer;
}

.selected_item_delete_icon {
	position: absolute;
	top: 0px;
	right: 8px;
	cursor: pointer;
	width: 5px;
	height: 5px;
}

.selected_items_empty {
	color: gray;
	text-align: start;
	font-size: 14px;
	width: 100%;
}

.selected_item__info_title {
	font-weight: 700;
}
