@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.shrtTxt {
	font-size: 13px;
	line-height: 16px;
	@include adapt(768px) {
		display: none;
	}
}
.svg {
	width: 18px;
	height: 18px;
	svg {
		width: 100%;
		height: 100%;
		fill: $black;
	}
}
.activeBtn {
	border: 1px solid $main-blue !important;
	.shrtTxt {
		color: $main-blue;
	}
}
.activeSvg {
	svg {
		fill: $main-blue;
	}
}
.list {
	padding: 8px;
	background-color: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	width: 168px;
}
.subList {
	padding: 8px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}
.item {
	margin-left: 0 !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	border-radius: 4px !important;
	svg {
		stroke: $light-grey !important;
	}
}
.itemActive {
	background-color: rgba(27, 170, 240, 0.1) !important;
	color: $main-blue !important;
	svg {
		stroke: $main-blue !important;
	}
}

.rotate {
	svg {
		transform: rotate(-90deg);
	}
}

.mt {
	margin-top: 8px;
}

.infoBlock {
	padding: 1em;
	color: grey;
}

.infoBlock > span {
	display: block;
	margin: 15px 0 7px 0;
	color: rgba(0, 0, 0, 0.87);
	font-size: 16px;
	font-weight: 500;
}

.infoBlock > table {
	border: solid 1px grey;
	display: block;
	margin-top: 1em;
}

.infoBlock > table > tr > th {
	padding: 5px;
	border: solid 1px grey;
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.87);
}

.infoBlock > table > tr > td {
	padding: 5px;
	border: solid 1px grey;
	font-size: 14px;
}

.infoLi {
	color: grey;
	display: table;
	font-size: 14px;
}

.infoLi > li {
	margin-top: 3px;
}

.infoLi > li > b {
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.87);
	margin-right: 5px;
}
