@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.project__head {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.project__container {
	padding: 16px;

	@include adapt(480px) {
		padding: 0 16px;
	}
}
.projects__head-info {
	@include flex-between;
	align-items: center;

	@include adapt(768px) {
		justify-content: flex-end;
	}
}
.desktop-tabs {
	@include adapt(480px) {
		display: none;
	}
}
.mobile-tabs {
	display: none;

	@include adapt(480px) {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.shrt-txt {
		font-size: 14px;
		line-height: 16px;
	}
}
.projects__sort {
	width: 138px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: $border;
	border-radius: 4px;
	padding: 0 4px;
	margin-right: 8px;
}

.projects__sort-select {
	display: flex;
	align-items: center;
	justify-content: center;
	&-option {
		font-size: 13px;
		max-width: 98px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		cursor: pointer;
	}
}
.projects__sort__arrow-options {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 16px;
	margin-left: 12px;
	cursor: pointer;

	.ArrowDownSVG {
		width: 8px;
		height: 10px;
		position: relative;
		path {
			stroke: rgba(63, 79, 94, 0.3) !important;
		}
		&:first-child {
			transform: rotate(180deg);
		}
	}
	&.active-up {
		.ArrowDownSVG {
			&:first-child {
				path {
					stroke: $blue-dark !important;
				}
			}
		}
	}
	&.active-down {
		.ArrowDownSVG {
			&:last-child {
				path {
					stroke: $blue-dark !important;
				}
			}
		}
	}
}

.projects__head-top {
	display: block;
	margin: 8px 0;
}
.projects__head-footer {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	margin: 8px 0;
}
.projects__head-info-fix-width {
	display: flex;
	@include flex-between-center;
	min-width: 250px;
	@include adapt(1040px) {
		width: 260px;
	}
	@include adapt(992px) {
		display: none;
	}
}
.project__head-subtitle {
	margin-right: 8px;
	color: $grey;
	font-size: 16px;

	@include adapt(1040px) {
		margin: 0 4px;
		font-size: 16px;
	}
}
.project__head-value {
	margin-right: 16px;
	font-size: 18px;

	@include adapt(1040px) {
		margin: 0 4px;
		font-size: 16px;
	}
}

.projects__head-sum {
	font-size: 18px;
	@include adapt(1040px) {
		font-size: 16px;
	}
	@include adapt(768px) {
		display: none;
	}
}
.projects__head__left {
	display: flex;
	align-items: center;
}
.projects__head__right {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include adapt(480px) {
		justify-content: flex-end;
	}
	.star-icon {
		width: 14px;
		height: 14px;
		margin-right: 12px;
	}
}

.projects__head-buttons {
	display: flex;
	align-items: center;
	gap: 16px;
}

.projects__head__filter-btn {
	display: none !important;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: $black !important;
	margin-right: 8px !important;

	@include adapt(992px) {
		display: flex !important;
		width: 80px !important;
		svg {
			fill: #3f4f5e;
			display: block;
			width: 14px;
			height: 16px;
			margin-right: 8px;
		}
	}

	&.active {
		svg {
			fill: $main-blue;
		}
	}
}
.projects__head-middle {
	margin: 0 10px;
}
