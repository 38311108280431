@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.header {
	position: relative;
	background-color: $blue-dark;
	width: 100%;
	height: 68px;
}
.container {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
}

.container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
}

.containerFull {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 16px;
}

.demoSign {
	text-align: center;
	padding: 10px;
}
.demoSign > Img {
	display: inline-block;
	vertical-align: top;
}
.demoSign > Span {
	display: inline-block;
	font-size: 13px;
	font-weight: 500;
	color: #fff;
	vertical-align: -webkit-baseline-middle;
	margin-left: 8px;
}
