@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.comment {
	padding: 8px;

	textarea {
		border: 1px solid $border-color;
		border-radius: 4px;
		width: 100%;
		padding: 8px;
		resize: none;
		outline: none;
		font-size: 14px;
		line-height: 16px;
		height: 80px;
	}
	::placeholder {
		color: $placeholder-color;
		font-size: 14px;
		line-height: 16px;
	}
}
.row-cell {
	flex-wrap: nowrap !important;
	display: flex;
	align-items: flex-end;
}
.cell_wide {
	width: 100% !important;
	padding: 0 6px;
}
.MuiFormControl-root-189 {
	border-radius: 5px;
}
.selectFocus {
	border: 1px solid rgb(0, 149, 255) !important;
}
