.item {
	margin-top: 8px;
	&:first-child {
		margin-top: 0;
	}
}
.action {
	margin-top: 16px;
	display: flex;
	justify-content: space-between;
}
