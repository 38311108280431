.filter_presets {
	padding-right: 20px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

	&__items {
		margin-top: 8px;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;

		&__item {
			background-color: #3f4f5e;
			color: #fff;
		}
	}

	&__save_modal {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// font-size: 15px;
		// padding-left: 5px;
		margin-top: 4px;
	}
}
