@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.container {
	overflow: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(148, 147, 147, 0.34);
		border: 2px solid transparent;
		border-radius: 8px;
		background-clip: content-box;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 2px rgba(69, 69, 69, 0.455);
		background-color: $table-bg;
		border-radius: 8px;
		background-clip: content-box;
		width: 1px;
	}
}
.table {
	margin: 0 auto;
	margin-top: 12px;
	padding: 16px 16px;
	width: 1280px;
}
.grid {
	display: grid;
}
.alignTheElement {
	align-items: center;
	height: 100%;
}
.thead {
	align-items: center;
	height: 32px;
	background-color: $table-bg;
	font-size: 12px;
	line-height: 16px;
	color: $light-grey;
}
.theadCell {
	padding-left: 4px;
	padding-right: 4px;
}
.textAlignLeft {
	text-align: left;
}
.textAlignRight {
	text-align: right;
}
.textAlignCenter {
	text-align: center;
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 32px;
	font-size: 14px;
	line-height: 24px;
	position: relative;
}
.activeRow {
	background: $table-bg;
}
.rowWrapper {
	height: 32px;
	position: relative;
	&:focus {
		outline: none;
		border: none;
	}
}
.uncCounter {
	display: block;
	width: 100%;
	height: 24px;
	text-align: left;
	border: 1px solid $border-color;
	padding: 4px 8px;
	border-radius: 4px;
	&::placeholder {
		color: $light-grey !important;
	}
}
.accent {
	color: $main-blue;
}
.regionName {
	font-size: 12px;
}
.rowTitle {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 14px;
	line-height: 16px;
	padding-left: 24px;
	color: $grey;
}
.TitleWrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.open {
	transform: rotate(180deg);
}
.total {
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
}
.rowItem {
	position: relative;
	height: 32px;
	border: $border;
	border-top: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	&:first-child {
		border-top: $border;
	}
}
.rowInFilter {
	background-color: #e8f6dc;
	border: 1px solid #8fd14f;
}
.itm {
	margin-top: 16px;
}
.arrow {
	margin-right: 8px;
}
.tableCell {
	position: relative;
	padding-left: 4px;
	padding-right: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.nothing {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	color: $light-grey;
	height: 32px;
	line-height: 50px;
	border: $border;
	padding-left: 40px;
}
:global() {
	.rowWrapper {
		&:hover {
			.delBtn {
				opacity: 1;
			}
		}
	}
}
.delBtn {
	width: 24px;
	height: 24px;
	right: -101%;
	top: calc(-50% - 12px);
	position: absolute;
	padding: 0;
	opacity: 0;
	background-image: url('/assets/images/Group_2177.png');
	cursor: pointer;
	z-index: 2;
	@include adapt(1280px) {
		right: -99%;
	}
}
