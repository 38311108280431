$main-blue: #1baaf0;
$blue: #1baaf0;

// $filter-blue: #f5f8fd;
$filter-blue: #f5f5f5;
$black: rgba(0, 0, 0, 0.87);
$grey: rgba(0, 0, 0, 0.56);
$border: 1px solid #e0e0e0;

$transition: all 0.15s ease-in-out;
$green: #71dc6f;
$blue-dark: #3f4f5e;
$error: #ff5c5c;
$light-grey: rgba(0, 0, 0, 0.34);
$table-bg: rgba(0, 0, 0, 0.03);
$color-btn: #02405e;
$border-color: #e0e0e0;
$bg-personal-aria: #e5e5e5;
$white: #fff;
$placeholder-color: rgba(0, 0, 0, 0.45);

$margin: 8px;
