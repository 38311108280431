.modal_preset_filter {
	display: flex;
	align-items: center;
	justify-content: center;
	// width: 100vw;
}

.modal_preset_filter__content {
	background-color: #f5f5f5;
	padding: 24px;
	max-width: 450px;
	width: 100%;

	border-radius: 5px;
	outline: none;

	&__title {
		text-align: left;
		font-weight: 500;
		font-size: 18px;
		padding: 20px 0;
	}

	&__block {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin: 20px 0 30px 0;

		&__input {
			padding: 8px;
			border: 1px solid #e2e2e1;
			border-radius: 4px;
			max-width: 100%;
			min-height: 34px;

			&::placeholder {
				color: rgb(165, 165, 165);
			}

			&:focus {
				outline: 1px solid rgb(42, 164, 246);
			}
		}
	}

	&__btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		margin-right: 10px;
	}
}
