@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.duration-date {
	margin-top: $margin;
	&__head {
		font-size: 12px;
		@include flex-between-center;
		margin-bottom: $margin;

		&-title {
			padding-right: 4;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
		}
	}

	&__pickers {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 5px;
		margin-bottom: $margin;

		&-item:first-child {
			margin-right: 4;
		}

		& .MuiFormControl-marginNormal {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&__checkbox {
		display: flex;
		align-items: center;
	}
	.MuiInputBase-input {
		color: $black !important;
	}
}
